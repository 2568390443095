import React, {useState} from 'react';

const ModalContext = React.createContext();

const ModalProvider = props => {
    const [stack, setStack] = useState([]);

    const setModal = (modal) => {
        //modal.props.key = stack.length;
        if(modal === null) {
            setStack([]);
        }
        else {
            setStack(stack => [...stack, modal]);
        }
    }

    const backtrack = () => {
        // NOTE: Spread syntax allows multiple backtrack calls to work as expected.
        setStack(stack => [...stack.slice(0, stack.length-1)]);
    }

    return <>
        <ModalContext.Provider value={{setModal: setModal, backtrack: backtrack}}>
            {/** Stack is mapped so that keys can be injected, as per warnings. */}
            {stack.map((modal, i) => <div key={i}>{modal}</div>)}
            {props.children}
        </ModalContext.Provider>
    </>
}

export { ModalContext as default, ModalProvider };