/**
 * @param {string} iso a YYYY-MM-DD formatted string
 * @returns {Date} a Date object
 */
 function dateFromISOFragment(iso) {
    return new Date(`${iso}T13:00:00`); // time helps ensure the date stays as the intended date
}

/**
 * @param {Date} date 
 * @returns {string} a YYYY-MM-DD format string of the date
 */
function dateToISOFragment(date) {
    if(Object.prototype.toString.call(date) !== '[object Date]') {
        console.error(`dateToISOFragment argument: ${date}`);
        throw new TypeError(`dateToISOFragment requires a Date type input value (type is '${Object.prototype.toString.call(date)}')`);
    }
    return date.toISOString().substring(0,10);
}

/**
 * @param {String} baseDayStr
 */
function getWeek(baseDayStr) {
    let firstDay = dateFromISOFragment(baseDayStr);
    firstDay.setDate(firstDay.getDate() - firstDay.getDay());
    let dayStrs = [];
    for(let d = 0; d <= 6; d++) {
        const day = dateFromISOFragment(dateToISOFragment(firstDay));
        day.setDate(day.getDate() + d);
        dayStrs.push(dateToISOFragment(day));
    }
    return dayStrs;
}

/**
 * @param {number} num an integer 0-6 indicating day of week
 * @returns the string representation of the day of the week
 */
function dayOfWeekStr(num) {
    switch(num) {
        case 0:
            return 'Sunday';
        case 1:
            return 'Monday';
        case 2:
            return 'Tuesday';
        case 3:
            return 'Wednesday';
        case 4:
            return 'Thursday';
        case 5:
            return 'Friday';
        case 6:
            return 'Saturday';
        default:
            return 'DAY'
    }
}

/**
 * @param {number} num an integer 0-11 indicating month
 * @returns the unabbreviated string representation of the month
 */
function monthStr(num) {
    switch(num) {
        case 0:
            return 'January';
        case 1:
            return 'February';
        case 2:
            return 'March';
        case 3:
            return 'April';
        case 4:
            return 'May';
        case 5:
            return 'June';
        case 6:
            return 'July';
        case 7:
            return 'August';
        case 8:
            return 'September';
        case 9:
            return 'October';
        case 10:
            return 'November';
        case 11:
            return 'December';
        default:
            return 'MONTH'
    }
}

/**
 * @param {object} record the record object
 * @param {string} status the status to find the timestamp and blame for
 * @returns an object containing the 'timestamp' and 'blame'
 */
const getStatusTimestamp = (record, status) => {
    if(record.changelog.length > 0) {
        for(let i = record.changelog.length-1; i >= 0; i--) {
            for(const change of record.changelog[i].changes) {
                if(change.property === "status" && change.to === status) {
                    return {
                        timestamp: record.changelog[i].timestamp,
                        blame: record.changelog[i].blame
                    };
                }
            }
        }
    }
    else {
        return {
            timestamp: record.createdAt,
            blame: record.user.email
        }
    }
}

/**
 * Checks if a given user is a Site Forms user
 * @param {object} user the User object (`user.email` is checked)
 * @returns {boolean} whether or not `user` is a Site Forms user
 */
function isSiteFormsUser(user) {
    const siteFormsUsers = ['lisa@gocbit.com', 'travis@gocbit.com', 'jill@gocbit.com', 'michelle@gocbit.com']; // TODO: Admin space for setting this list instead of hard coding?
    return siteFormsUsers.includes(user.email);
}

/**
 * Downloads a blob as a file
 * @param {Blob} blob The blob to be downloaded
 * @param {string} filename The name of the downloaded file
 */
function downloadBlob(blob, filename) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
}

/**
 * Get all days within the given range as strings in YYYY-MM-DD format
 * @param {string} startDateStr A date string in YYYY-MM-DD format
 * @param {string} endDateStr A date string in YYYY-MM-DD format
 * @returns {Array<string>} An array of dates between `startDateStr` and `endDateStr` (inclusive) in YYYY-MM-DD format
 */
function extrapolateDays(startDateStr, endDateStr) {
    let dayStrs = [];
    for(let day = dateFromISOFragment(startDateStr); dateToISOFragment(day) <= endDateStr; day.setDate(day.getDate() + 1)) {
        dayStrs.push(dateToISOFragment(day));
    }
    return dayStrs;
}

/**
 * 
 * @param {number} yearNum 4-digit year
 * @param {number} monthNum 0-indexed month number
 * @returns 
 */
function extrapolateDaysInMonth(yearNum, monthNum) {
    let monthStr = `${monthNum+1 <= 9 ? '0' : ''}${monthNum+1}`;
    let startDateStr = `${yearNum}-${monthStr}-01`;
    let endDateStr = `${yearNum}-${monthStr}-31`;
    return extrapolateDays(startDateStr, endDateStr);
}

function dayIsMonThurs(day) {
    const date = dateFromISOFragment(day);
    return date.getDay() >= 1 && date.getDay() <= 4;
}

function dayIsHoliday(dayStr) {
    let holidays = localStorage.getItem('holidays');
    if(!holidays) return false;
    holidays = JSON.parse(holidays);
    for(const holiday of holidays) {
        if(holiday.date === dayStr) return true;
    }
    return false;
}

const totalHours = (entries, cacn, day) => {
    let total = 0.0;
    for(const entry of entries) {
        if(entry.cacn === cacn && entry.day === day) {
            total += entry.adjustedHours;
        }
    }
    return total;
}

const totalAmount = (entries, cacn, day, items) => {
    let total = 0.0;
    for(const entry of entries) {
        if(entry.cacn === cacn && entry.day === day) {
            for(const item of items) {
                if(item.user === entry.user.email && (entry.day >= item.startDate && entry.day <= item.endDate)) {
                    total += entry.adjustedHours * item.price;
                    break;
                }
            }
        }
    }
    return total;
}

const colNumToLetter = (num) => {
    const digits = Math.ceil(num / 25) > 0 ? Math.ceil(num / 25) : 1;
    let letters = "";
    for(let i = 0; i < digits; i++) {
        if(num > 25) {
            letters += 'A';
        }
        else {
            letters += String.fromCharCode('A'.charCodeAt(0) + num);
        }
        num -= 25;
    }
    return letters;
}

const generateDataSet = (entries, totalFunc, items) => {
    const allDays = entries.map(entry => entry.day);
    let uniqueDays = [];
    for(const day of allDays) {
        if(!uniqueDays.includes(day))
            uniqueDays.push(day);
    }
    uniqueDays = uniqueDays.sort();

    let excelHeaders = [{string: "CACN", key: 'cacn'}];
    for(const day of uniqueDays) {
        excelHeaders.push({date: helpers.dateFromISOFragment(day), key: day});
    }
    excelHeaders.push({string: "Total", key: 'total'});

    let allCACNs = entries.map(entry => entry.cacn);
    let uniqueCACNs = [];
    for(const cacn of allCACNs) {
        if(!uniqueCACNs.includes(cacn))
            uniqueCACNs.push(cacn);
    }
    uniqueCACNs = uniqueCACNs.sort();

    let excelData = [];
    for(let i = 0; i < uniqueCACNs.length; i++) {
        const cacn = uniqueCACNs[i];
        let row = {};
        row.cacn = {string: cacn};
        for(const day of uniqueDays) {
            row[day] = {number: items ? totalFunc(entries, cacn, day, items) : totalFunc(entries, cacn, day)};
            if(items) {
                row[day].style = {numberFormat: "$#,##0.00; ($#,##0.00); -"};
            }
        }
        row.total = {formula: `=SUM(${colNumToLetter(1)}${i+2}:${colNumToLetter(excelHeaders.length-2)}${i+2})`}
        if(items) {
            row.total.style = {numberFormat: "$#,##0.00; ($#,##0.00); -"};
        }
        excelData.push(row);
    }

    let totalRow = {cacn: {string: "Total"}};
    for(let i = 0; i < excelHeaders.length; i++) {
        const header = excelHeaders[i];
        if(header.key !== "cacn") {
            totalRow[header.key] = {formula: `=SUM(${colNumToLetter(i)}2:${colNumToLetter(i)}${excelData.length+1})`};
            if(items) {
                totalRow[header.key].style = {numberFormat: "$#,##0.00; ($#,##0.00); -"};
            }
        }
    }
    excelData.push(totalRow);
    return {
        headers: excelHeaders,
        data: excelData
    }
}

const generateLaborSummaryDataSet = (entries, totalFunc, items) => {
    const allDays = entries.map(entry => entry.day);
    let uniqueDays = [];
    for(const day of allDays) {
        if(!uniqueDays.includes(day))
            uniqueDays.push(day);
    }
    uniqueDays = uniqueDays.sort();

    const dayIsEstimated = (day) => {
        const entry = entries.find(entry => entry.day === day);
        return entry ? entry.estimated : false;
    }

    let excelHeaders = [{string: "CACN", key: 'cacn'}];
    for(const day of uniqueDays) {
        excelHeaders.push({date: helpers.dateFromISOFragment(day), key: day, style: dayIsEstimated(day) ? {font: {color: '#FF0000'}} : undefined});
    }
    excelHeaders.push({string: "Total", key: 'total'});

    let allCACNs = entries.map(entry => entry.cacn);
    let uniqueCACNs = [];
    for(const cacn of allCACNs) {
        if(!uniqueCACNs.includes(cacn))
            uniqueCACNs.push(cacn);
    }
    uniqueCACNs = uniqueCACNs.sort();

    let excelData = [];
    for(let i = 0; i < uniqueCACNs.length; i++) {
        const cacn = uniqueCACNs[i];
        let row = {};
        row.cacn = {string: cacn};
        for(const day of uniqueDays) {
            row[day] = {number: items ? totalFunc(entries, cacn, day, items) : totalFunc(entries, cacn, day)};
            if(items) {
                row[day].style = {numberFormat: "$#,##0.00; ($#,##0.00); -"};
            }
        }
        row.total = {formula: `=SUM(${colNumToLetter(1)}${i+2}:${colNumToLetter(excelHeaders.length-2)}${i+2})`}
        if(items) {
            row.total.style = {numberFormat: "$#,##0.00; ($#,##0.00); -"};
        }
        excelData.push(row);
    }

    let totalRow = {cacn: {string: "Total"}};
    for(let i = 0; i < excelHeaders.length; i++) {
        const header = excelHeaders[i];
        if(header.key !== "cacn") {
            totalRow[header.key] = {formula: `=SUM(${colNumToLetter(i)}2:${colNumToLetter(i)}${excelData.length+1})`};
            if(items) {
                totalRow[header.key].style = {numberFormat: "$#,##0.00; ($#,##0.00); -"};
            }
        }
    }
    excelData.push(totalRow);
    return {
        headers: excelHeaders,
        data: excelData
    }
}

const dayIsWeekend = (dayStr) => {
    const date = dateFromISOFragment(dayStr);
    const day = date.getDay();
    // Sunday = 0; Saturday = 6
    return day === 0 || day === 6;
}

const helpers = {
    dateFromISOFragment: dateFromISOFragment,
    dateToISOFragment: dateToISOFragment,
    getWeek: getWeek,
    dayOfWeekStr: dayOfWeekStr,
    monthStr: monthStr,
    getStatusTimestamp: getStatusTimestamp,
    isSiteFormsUser: isSiteFormsUser,
    downloadBlob: downloadBlob,
    extrapolateDays: extrapolateDays,
    extrapolateDaysInMonth: extrapolateDaysInMonth,
    dayIsMonThurs: dayIsMonThurs,
    dayIsHoliday: dayIsHoliday,
    generateLaborSummaryDataSet: generateLaborSummaryDataSet,
    generateDataSet: generateDataSet,
    totalAmount: totalAmount,
    totalHours: totalHours,
    dayIsWeekend: dayIsWeekend
}

export default helpers;