function LoadingSpinner(props) {
    const style = {
        fontSize: props.size ? props.size : '50px',
    }
    if(props.color) {
        style.color = props.color
    }

    return(
        <>
        {!props.inline ?
        <center>
            <div style={style}>
                <i className="fas fa-spinner"/>
            </div>
        </center>
        :
        <div style={style}>
            <i className="fas fa-spinner"/>
        </div>
        }
        </>
    )
}

export default LoadingSpinner;