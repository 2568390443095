import React, {useState} from 'react';

import api from '../api';

const ExportEntriesButton = ({entries, filename}) => {
    const [loading, setLoading] = useState(false);

    const generateDataSet = () => {
        const headers = [
            {string: "Day", key: "day"},
            {string: "User", key: "user"},
            {string: "Type", key: "type"},
            {string: "CACN", key: "cacn"},
            {string: "Hours", key: "hours"},
            {string: "Adjusted Hours", key: "adjustedHours"},
            {string: "Description", key: "description"},
            {string: "Requestor", key: "requestor"},
            {string: "Work Order Number", key: "workOrderNumber"},
            {string: "Work Order Position Title", key: "workOrderPositionTitle"},
            {string: "Locked?", key: "locked"},
            {string: "Created By", key: "createdBy"},
            {string: "Last Modified By", key: "modifiedBy"},
            {string: "Created At", key: "createdAt"},
            {string: "Last Modified At", key: "updatedAt"}
        ]
        let data = [];
        for(const entry of entries) {
            data.push({
                day: {string: entry.day},
                user: {string: `${entry.user.first} ${entry.user.last} (${entry.user.email})`},
                type: {string: entry.type},
                cacn: {string: entry.cacn},
                hours: {number: entry.hours},
                adjustedHours: {number: entry.adjustedHours},
                description: {string: entry.description},
                requestor: {string: entry.requestor},
                workOrderNumber: {string: entry.workOrder.number},
                workOrderPositionTitle: {string: entry.workOrder.positionTitle},
                locked: {bool: entry.locked},
                createdBy: {string: entry.createdBy},
                modifiedBy: {string: entry.modifiedBy},
                createdAt: {date: new Date(entry.createdAt)},
                updatedAt: {date: new Date(entry.updatedAt)}
            })
        }
        return {
            headers: headers,
            data: data,
            worksheet: "Entries"
        }
    }

    const run = (e) => {
        e.preventDefault();
        setLoading(true);
        api.createXLSX(generateDataSet())
        .then(blob => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        })
        .catch(err => {
            console.error(err);
            alert(`Export Error: ${err.toString()}`);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    return (
        <button className="btn btn-primary" onClick={run}>
            <i className={loading ? "fas fa-spinner" : "fas fa-up-right-from-square"}/>&nbsp;{loading ? "Exporting..." : "Export Entries"}
        </button>
    )
}

export default ExportEntriesButton;