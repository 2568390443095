import React, { useState, useEffect, useRef } from 'react';

import helpers from '../helpers';

function WeekSelector(props) {
    const nextWeekBtn = useRef(null);
    const prevWeekBtn = useRef(null);
    const [weekOfStr, setWeekOfStr] = useState(null);

    useEffect(() => {
        if(nextWeekBtn.current && document.activeElement === nextWeekBtn.current && tooFarAhead()) {
            nextWeekBtn.current.blur();
        }
        if(prevWeekBtn.current && document.activeElement === prevWeekBtn.current && tooFarBack()) {
            prevWeekBtn.current.blur();
        }
    });

    useEffect(() => {
        const dayStrs = helpers.getWeek(props.baseDayStr);
        const firstDay = helpers.dateFromISOFragment(dayStrs[0]);
        const lastDay = helpers.dateFromISOFragment(dayStrs[dayStrs.length-1]);
        if(firstDay.getFullYear() === lastDay.getFullYear()) {
            if(firstDay.getMonth() === lastDay.getMonth()) {
                setWeekOfStr(`Week of ${helpers.monthStr(firstDay.getMonth())} ${firstDay.getDate()}-${lastDay.getDate()}, ${firstDay.getFullYear()}`);
            }
            else {
                setWeekOfStr(`Week of ${helpers.monthStr(firstDay.getMonth())} ${firstDay.getDate()} - ${helpers.monthStr(lastDay.getMonth())} ${lastDay.getDate()}, ${firstDay.getFullYear()}`);
            }
        }
        else {
            setWeekOfStr(`Week of ${helpers.monthStr(firstDay.getMonth())} ${firstDay.getDate()}, ${firstDay.getFullYear()} - ${helpers.monthStr(lastDay.getMonth())} ${lastDay.getDate()}, ${lastDay.getFullYear()}`);
        }
    }, [props.baseDayStr]);

    const tooFarAhead = () => {
        const dayStrs = helpers.getWeek(props.baseDayStr);
        return dayStrs.includes('2023-09-30');
    }

    const tooFarBack = () => {
        const dayStrs = helpers.getWeek(props.baseDayStr);
        return dayStrs.includes('2022-04-10');
    }

    const prevWeek = (event) => {
        event.preventDefault();
        let newBaseDay = helpers.dateFromISOFragment(props.baseDayStr);
        newBaseDay.setDate(newBaseDay.getDate() - 7);
        props.setBaseDayStr(helpers.dateToISOFragment(newBaseDay));
    }

    const nextWeek = (event) => {
        event.preventDefault();
        let newBaseDay = helpers.dateFromISOFragment(props.baseDayStr);
        newBaseDay.setDate(newBaseDay.getDate() + 7);
        props.setBaseDayStr(helpers.dateToISOFragment(newBaseDay));
    }

    return(
        <>
            <button className="btn" onClick={prevWeek} disabled={tooFarBack()} ref={prevWeekBtn}>
                <i className={tooFarBack() ? "fas fa-angle-left text-white" : "fas fa-angle-left"}/>
            </button>
            {weekOfStr}
            <button className="btn" onClick={nextWeek} disabled={tooFarAhead()} ref={nextWeekBtn}>
                <i className={tooFarAhead() ? "fas fa-angle-right text-white" : "fas fa-angle-right"}/>
            </button>
        </>
    )
}

export default WeekSelector;