import React, { useState, useEffect } from 'react';

import EntriesTable from './EntriesTable';
import helpers from '../helpers';
import LoadingSpinner from './LoadingSpinner';
import api from '../api';

function TimeActivities(props) {
    const [entries, setEntries] = useState([]);
    const [loadedEntries, setLoadedEntries] = useState(false);

    useEffect(() => {
        // Get all entries for the month to be displayed in a single table.
        let daysInMonth = helpers.extrapolateDaysInMonth(props.year, props.month);
        api.getEntriesForUser(props.user.email, daysInMonth[0], daysInMonth[daysInMonth-1])
        .then(entries => {
            setEntries(entries.sort((a,b) => a.day < b.day ? -1 : 1));
            setLoadedEntries(true);
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                alert("ERROR: Failed to get TimecardEntries");
            }
        });
    }, []);

    const today = new Date();

    return(
        <div>
            <p className="mt-1 text-center">
                <span className="fs-3">CBIT Inc.</span><br/>
                <span className="fs-5">Time Activities by {props.user.first} {props.user.last}</span><br/>
                <span className="fs-6">Activity: {helpers.monthStr(props.month)} {props.year}</span><br/>
                <span className="fs-6">Date: {helpers.monthStr(today.getMonth())} {today.getDate()}, {today.getFullYear()}</span>
            </p>
            {loadedEntries ? 
                <>
                {entries.length > 0 ?
                    <div className="mt-3">
                        <EntriesTable entries={entries} setEntries={setEntries} readonly showDay showTotal/>
                    </div>
                :
                    <p className="text-center">
                        <i>This user has no approved time entries for this month.</i>
                    </p>
                }
                </>
            :<LoadingSpinner size="25px"/>}
        </div>
    )
}

export default TimeActivities