import React, { useState, useEffect, useRef, useContext } from 'react';

import helpers from '../helpers';
import EntriesTable from './EntriesTable';
import LoadingSpinner from './LoadingSpinner';
import Modal from './Modal';
import UserContext from '../UserContext';
import ModalContext from './ModalContext';
import api from '../api';

function DaySection(props) {
    const [prevDay, setPrevDay] = useState(props.day);
    const [loading, setLoading] = useState(true);
    const isInitialMount = useRef(true);
    const userContext = useContext(UserContext);
    const modaling = useContext(ModalContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if((prevDay !== props.day || isInitialMount.current) && props.day) {
            setLoading(true);
            api.getEntriesForUser(userContext.loggedInUser.email, props.day, props.day, {signal: signal})
            .then(entries => {
                props.setEntries(entries);
                setLoading(false);
                setPrevDay(props.day);
                isInitialMount.current = false;
            })
            .catch(e => {
                if(e.name !== "AbortError") {
                    console.error(e);
                    alert("Failed to retrieve entries. Please refresh and try again. Alert the website administrator if the problem persists.");
                }
            })
        }

        return () => {
            controller.abort();
        }
    });

    const dateDisplayStr = (str) => {
        const date = helpers.dateFromISOFragment(str);
        return `${helpers.dayOfWeekStr(date.getDay())}, ${helpers.monthStr(date.getMonth())} ${date.getDate()}`;
    }

    const copyPrevWeek = async() => {
        // Archive all existing entries for this day.
        // Grab the previous week's entries for this day and create new ones with identical information, but using this DaySection's day.
        let newEntries = [];
        for(const entry of props.entries) {
            try {
                await api.deleteEntryByID(entry._id);
            }
            catch(err) {
                if(!err.name !== "AbortError") {
                    console.error(err);
                    throw new Error(`ERROR: Failed to clear entries for ${props.day}, cannot copy prev week`);
                }
            }
        }
        let prevWeekBase = helpers.dateFromISOFragment(props.day);
        prevWeekBase.setDate(prevWeekBase.getDate() - 7);
        const prevWeekBaseStr = helpers.dateToISOFragment(prevWeekBase);

        try {
            const entries = await api.getEntriesForUser(userContext.loggedInUser.email, prevWeekBaseStr, prevWeekBaseStr);
            for(const entry of entries) {
                entry.day = props.day;
                entry.locked = false;
                try {
                    newEntries.push(await api.createEntry(entry));
                }
                catch(err) {
                    if(err.name !== "AbortError") {
                        console.error(err);
                        throw new Error("ERROR: Failed to copy entry from prev week");
                    }
                }
            }
        }
        catch(err) {
            if(!err.name !== "AbortError") {
                console.error(err);
                throw new Error("ERROR: Failed to copy prev week");
            }
        }

        return newEntries;
    }

    const confirmCopy = (event) => {
        event.preventDefault();
        const choices = [
            {
                btnColor: 'warning',
                btnInner: <span><i className="fas fa-arrow-right"/>&nbsp;Yes, Copy Previous Week</span>,
                func: async(event) => {
                    event.preventDefault();
                    modaling.setModal(null);
                    try {
                        const newEntries = await copyPrevWeek();
                        if(newEntries) {
                            props.setEntries(newEntries);
                        }
                    }
                    catch(err) {
                        console.error(err);
                        alert(err.toString());
                    }
                }
            },
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Cancel</span>,
                func: (event) => {
                    event.preventDefault();
                    modaling.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[1].func}>
            <h3>Copy from previous week?</h3>
            <p>
                By copying from the previous week, you will overwrite any data for the current day.
            </p>
        </Modal>
        modaling.setModal(modal);
    }

    const holidayDisplay = () => {
        if(!props.holidays) return null;
        for(const holiday of props.holidays) {
            if(holiday.date === props.day) {
                return <>&nbsp;<span className="text-danger"><strong>({holiday.name})</strong></span></>;
            }
        }
        return null;
    }

    return (
        <div>
            <div className="row border-bottom mb-4">
                <div className="col text-start">
                    <h3>{dateDisplayStr(props.day)}{holidayDisplay()}</h3>{props.readonly ? <>&nbsp;<div className="alert alert-secondary">This day has been locked because it was processed in a report by your manager.</div></> : null}
                </div>
                <div className="col text-end">
                    <button className="btn btn-primary" onClick={confirmCopy} disabled={props.readonly}>
                        <i className="fas fa-copy"/>
                        &nbsp; Copy from Previous Week
                    </button>
                </div>
            </div>
            {loading ? 
                <LoadingSpinner size="50px"/>
            :
                <>
                    {props.entries.length === 0 ?
                    <h5><i>No entries for this date.</i></h5>
                    :null}
                    <EntriesTable entries={props.entries} setEntries={props.setEntries} day={props.day} showTotal={props.showTotal} readonly={props.readonly}/>
                </>
            }
        </div>
    )
}

export default DaySection;