import React, { useState, useEffect, useRef } from 'react';

import helpers from '../helpers';

function MonthSelector(props) {
    const [baseDayStr, setBaseDayStr] = useState(helpers.dateToISOFragment(new Date()));
    const nextWeekBtn = useRef(null);
    const prevWeekBtn = useRef(null);
    const [monthOfStr, setMonthOfStr] = useState(null);

    useEffect(() => {
        if(nextWeekBtn.current && document.activeElement === nextWeekBtn.current && tooFarAhead()) {
            nextWeekBtn.current.blur();
        }
        if(prevWeekBtn.current && document.activeElement === prevWeekBtn.current && tooFarBack()) {
            prevWeekBtn.current.blur();
        }
    });

    useEffect(() => {
        const baseDay = helpers.dateFromISOFragment(baseDayStr);
        setMonthOfStr(`${helpers.monthStr(baseDay.getMonth())}, ${baseDay.getFullYear()}`);
        
        if(props.setBaseDayStr)
            props.setBaseDayStr(baseDayStr);
        if(props.setBaseDay)
            props.setBaseDay(helpers.dateFromISOFragment(baseDayStr));
    }, [baseDayStr]);

    const tooFarAhead = () => {
        const baseDay = helpers.dateFromISOFragment(baseDayStr);
        const today = new Date();
        return baseDay.getMonth() === today.getMonth();
    }

    const tooFarBack = () => {
        const baseDay = helpers.dateFromISOFragment(baseDayStr);
        return baseDay.getMonth() === 3 && baseDay.getFullYear() === 2022; // Can't go farther back than April 2022
    }

    const moveMonth = (delta) => {
        let newBaseDay = helpers.dateFromISOFragment(baseDayStr);
        newBaseDay.setDate(1);
        newBaseDay.setMonth(newBaseDay.getMonth() + delta);
        setBaseDayStr(helpers.dateToISOFragment(newBaseDay));
    }

    const prevMonth = (event) => {
        event.preventDefault();
        moveMonth(-1);
    }

    const nextMonth = (event) => {
        event.preventDefault();
        moveMonth(1);
    }

    return(
        <>
            <button className="btn" onClick={prevMonth} disabled={tooFarBack()} ref={prevWeekBtn}>
                <i className={tooFarBack() ? "fas fa-angle-left text-white" : "fas fa-angle-left"}/>
            </button>
            {monthOfStr}
            <button className="btn" onClick={nextMonth} disabled={tooFarAhead()} ref={nextWeekBtn}>
                <i className={tooFarAhead() ? "fas fa-angle-right text-white" : "fas fa-angle-right"}/>
            </button>
        </>
    )
}

export default MonthSelector;