import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';

function PrintModal(props) {
    const firstFocusableItem = useRef(null);
    const lastFocusableItem = useRef(null);
    let componentRef = useRef();

    const clampTabbing = (e) => {
        const key = e.key;
        if(key === "Escape") {
            this.props.dismiss();
            return;
        }
        else if(key === "Tab" && e.shiftKey && firstFocusableItem.current && firstFocusableItem.current.contains(e.target)) {
            lastFocusableItem.current.focus();
            e.preventDefault();
            return;
        }
        else if(key === "Tab" && !e.shiftKey && lastFocusableItem.current && lastFocusableItem.current.contains(e.target)) {
            firstFocusableItem.current.focus();
            e.preventDefault();
            return;
        }
    }

    return(
        <div className="print-modal-backdrop">
            <div id="printContainer" onKeyDown={clampTabbing}>
                <button className="btn btn-secondary w-auto mt-2" onClick={props.dismiss} ref={firstFocusableItem}>
                    <i className="fas fa-arrow-left"/>
                    &nbsp; Close
                </button>
                <div className="print-instructions w-auto mt-2 mb-3">
                    <h3>Recommended Steps</h3>
                    <ol>
                        <li>
                            <ReactToPrint
                                trigger={() =>  <button className="btn btn-dark">
                                                    <i className="fas fa-print"/>
                                                    &nbsp; Click Here
                                                </button>}
                                content={() => componentRef}
                                documentTitle={props.documentTitle}
                            />
                            
                            &nbsp;to open the print dialog.
                        </li>
                        <li>
                            Set Destination/Printer to <strong>Save as PDF</strong>.
                        </li>
                        <li>Ensure the Layout/Orientation is in <strong>Portrait</strong> mode.</li>
                        <li>
                            Expand <strong>More Settings</strong>, and under <strong>Options</strong>, uncheck <strong>Headers and Footers</strong>.
                        </li>
                        <li>
                            Set the margins to your desired setting.
                        </li>
                        <li>
                            Click <strong>Save</strong>. Please be aware that the borders may not show correctly in the preview, but they are still present in the final document.
                        </li>
                    </ol>
                </div>
                <div className="printme w-auto" ref={(el) => (componentRef = el)}>
                    {props.children}
                </div>
                <button className="btn btn-secondary w-auto" onClick={props.dismiss} ref={lastFocusableItem}>
                    <i className="fas fa-arrow-left"/>
                    &nbsp; Close
                </button>
            </div>
        </div>
    )
}

export default PrintModal;