import React, {useState, useEffect, useContext} from 'react';

import helpers from '../helpers';
import api from '../api';
import LoadingSpinner from './LoadingSpinner';
import EntriesTable from './EntriesTable';
import BlobDownloadList from './BlobDownloadList';

const WorkOrderReconciliation = props => {
    const today = new Date();
    const [step, setStep] = useState(1);
    const [startDate, setStartDate] = useState(helpers.dateToISOFragment(today));
    const [endDate, setEndDate] = useState(helpers.dateToISOFragment(today));
    const [workOrders, setWorkOrders] = useState(null);
    const [selectedWorkOrderNumbers, setSelectedWorkOrderNumbers] = useState([]);
    const [entriesByUser, setEntriesByUser] = useState(null);
    const [entriesByWorkOrder, setEntriesByWorkOrder] = useState(null);
    const [results, setResults] = useState(null); // [{workOrderNumber, positionTitle, budgetedHours, hours, adjustedHours}]

    useEffect(() => {
        if(step < 1) {
            setStep(1);
        }
        if(step > 4) {
            setStep(4);
        }
        if(step < 3) {
            setEntriesByUser(null);
            setEntriesByWorkOrder(null);
        }
        
        if(step === 1) {
            setWorkOrders(null);
            setSelectedWorkOrderNumbers([]);
        }
        else if(step === 2 && workOrders === null) {
            api.getWorkOrderEntriesInRange(startDate, endDate)
            .then(workOrders => {
                setWorkOrders(workOrders);
            })
            .catch(err => {
                console.error(err);
                alert("ERROR: Failed to read Work Orders");
            })
        }
        else if(step === 3) {
            getEntries();
        }
        else if(step === 4) {
            runReports();
        }
    }, [step]);

    const handleChange = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        if(name === "startDate") {
            setStartDate(value);
        }
        else if(name === "endDate") {
            setEndDate(value);
        }
    }

    const handleSelectChange = (event) => {
        const workOrderNumber = event.target.name;
        if(event.target.checked) {
            if(!selectedWorkOrderNumbers.includes(workOrderNumber)) {
                setSelectedWorkOrderNumbers([...selectedWorkOrderNumbers, workOrderNumber]);
            }
        }
        else {
            let newSelected = [];
            for(const selectedNumber of selectedWorkOrderNumbers) {
                if(selectedNumber !== workOrderNumber) {
                    newSelected.push(selectedNumber);
                }
            }
            setSelectedWorkOrderNumbers(newSelected);
        }
    }

    const getEntries = async() => {
        const selectedWorkOrders = workOrders.filter((workOrder) => selectedWorkOrderNumbers.includes(workOrder.workOrderNumber));
        let entriesByWorkOrder = {};
        for(const workOrder of selectedWorkOrders) {
            try {
                entriesByWorkOrder[workOrder.workOrderNumber] = (await api.getEntriesByWorkOrderNumber(workOrder.workOrderNumber)).filter((entry) => entry.day >= startDate && entry.day <= endDate);
            }
            catch(err) {
                console.error(err);
                alert(`ERROR: Failed to read Entries for Work Order ${workOrder.workOrderNumber}`);
            }
        }
        setEntriesByWorkOrder(entriesByWorkOrder);
        let entriesByUser = {};
        for(const workOrderNumber in entriesByWorkOrder) {
            for(const entry of entriesByWorkOrder[workOrderNumber]) {
                if(entriesByUser[entry.user.email] === undefined) {
                    entriesByUser[entry.user.email] = [entry]
                }
                else {
                    entriesByUser[entry.user.email].push(entry);
                }
            }
        }
        let entriesByUserAsArray = [];
        for(const userEmail in entriesByUser) {
            const fullUserObject = entriesByUser[userEmail][0].user;
            entriesByUserAsArray.push([fullUserObject, entriesByUser[userEmail]])
        }
        setEntriesByUser(entriesByUserAsArray);
    }

    // Consolidate by positionTitle
    const consolidatedEntries = (entries) => {
        let pairs = []; // {title, entries}
        for(const entry of entries) {
            let foundPair = false;
            for(let i = 0; i < pairs.length; i++) {
                if(pairs[i].title === entry.workOrder.positionTitle) {
                    pairs[i].entries.push(entry);
                    pairs[i].hours += entry.hours;
                    pairs[i].adjustedHours += entry.adjustedHours;
                    foundPair = true;
                }
            }
            if(!foundPair) {
                pairs.push({
                    title: entry.workOrder.positionTitle,
                    entries: [entry],
                    hours: entry.hours,
                    adjustedHours: entry.adjustedHours
                })
            }
        }
        return pairs;
    }

    const runReports = () => {
        // [{workOrderNumber, positionTitle, budgetedHours, hours, adjustedHours}]
        const selectedWorkOrders = workOrders.filter((workOrder) => selectedWorkOrderNumbers.includes(workOrder.workOrderNumber));
        try {
            let _results = [];
            for(const workOrder of selectedWorkOrders) {
                const pairs = consolidatedEntries(entriesByWorkOrder[workOrder.workOrderNumber]);
                for(const pair of pairs) {
                    const position = workOrder.positions.find(pos => pos.positionTitle === pair.title);
                    if(position) {
                        _results.push({
                            workOrderNumber: workOrder.workOrderNumber,
                            positionTitle: pair.title,
                            budgetedHours: position.hoursBudget,
                            hours: pair.hours,
                            adjustedHours: pair.adjustedHours,
                        })
                    }
                    else {
                        alert(`ERROR: Could not find a position on ${workOrder.workOrderNumber} called ${pair.title}`);
                    }
                }
            }
            setResults(_results);
        }
        catch(err) {
            console.error(err);
            alert(`An unhandled error has occurred and the job cannot continue: ${err.toString()}`);
            return;
        }
    }

    const nextStep = (event) => {
        event.preventDefault();
        setStep(step+1);
    }

    const prevStep = (event) => {
        event.preventDefault();
        setStep(step-1);
    }

    const dateFormatStr = (dateStr) => {
        const date = helpers.dateFromISOFragment(dateStr);
        return date.toLocaleDateString();
    }

    const totalHours = (rows) => {
        let results = {
            totalBudgeted: 0,
            totalAdjusted: 0,
            totalReal: 0
        }
        for(const row of rows) {
            results.totalBudgeted += row.budgetedHours;
            results.totalAdjusted += row.adjustedHours;
            results.totalReal += row.hours;
        }
        return results;
    }

    return(
        <div>
            <h3>Work Order Reconciliation</h3>
            <div>
                <h4>Step 1: Choose Date Range</h4>
                <div className="row">
                    <div className="col d-flex flex-column align-items-center">
                        <label className="form-label"><b><u>Start Date:</u></b></label>
                        <input className="form-control w-fit" type="date" value={startDate} name="startDate" onChange={handleChange} disabled={step !== 1}/>
                    </div>
                    <div className="col d-flex flex-column align-items-center">
                        <label className="form-label"><b><u>End Date:</u></b></label>
                        <input className="form-control w-fit" type="date" value={endDate} name="endDate" onChange={handleChange} disabled={step !== 1}/>
                    </div>
                </div>
                {step === 1 ? 
                    <button className="btn btn-primary" onClick={nextStep}>
                        <i className="fas fa-arrow-right"/>&nbsp;Continue
                    </button>
                :null}
            </div>
            {step > 1 ? 
            <div>
                <h4>Step 2: Select Work Orders</h4>
                {workOrders ? 
                <>
                {workOrders.map((workOrder, i) => <div className="row text-start" key={i}>
                    <div className="col">
                        <div className="form-check">
                            <input id={`workOrderSelect${i}`} name={workOrder.workOrderNumber} type="checkbox" className="form-check-input" checked={selectedWorkOrderNumbers.includes(workOrder.workOrderNumber)} onChange={handleSelectChange} disabled={step !== 2}/>
                            <label className="form-check-label" htmlFor={`workOrderSelect${i}`}>
                                {workOrder.workOrderNumber}: {workOrder.positions.map(pos => pos.positionTitle).join('; ')} (Active Between {dateFormatStr(workOrder.startDate)} - {dateFormatStr(workOrder.endDate)})
                            </label>
                        </div>
                    </div>
                </div>)}
                </>
                : 
                <LoadingSpinner size={75}/>}
                {step === 2 ? 
                <>
                <button className="btn btn-secondary" onClick={prevStep}>
                    <i className="fas fa-arrow-left"/>&nbsp;Go Back
                </button>
                <button className="btn btn-primary" onClick={nextStep} disabled={selectedWorkOrderNumbers.length === 0}>
                    <i className="fas fa-arrow-right"/>&nbsp;Continue
                </button>
                </>
                :null}
            </div>
            :null}
            {step > 2 ?
            <div>
                <h4>Step 3: Preview Entries</h4>
                <p>The following entries will be considered by the report.</p>
                {entriesByUser ? 
                    <>
                    {entriesByUser.map(([user, entries], i) => <div key={i}>
                        <h5>Entries for {user.first} {user.last} ({user.email})</h5>
                        <EntriesTable forUser={user} entries={entries} showDay readonly showTotal/>
                    </div>)}
                    {step === 3 ? 
                    <>
                    <button className="btn btn-secondary" onClick={prevStep}>
                        <i className="fas fa-arrow-left"/>&nbsp;Go Back
                    </button>
                    <button className="btn btn-primary" onClick={nextStep} disabled={selectedWorkOrderNumbers.length === 0}>
                        <i className="fas fa-arrow-right"/>&nbsp;Run Report
                    </button>
                    </>
                    :null}
                    </>
                : <LoadingSpinner size={75}/>}
            </div>
            :null}
            {step === 4 ?
                <>
                <h4>Results:</h4>
                {results ? 
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Work Order Number</th>
                                <th>Position Title</th>
                                <th>Budgeted Hours</th>
                                <th>Adjusted Hours</th>
                                <th>Real Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {results.map((row, i) => <tr key={i}>
                                <td>{row.workOrderNumber}</td>
                                <td>{row.positionTitle}</td>
                                <td>{row.budgetedHours}</td>
                                <td>{row.adjustedHours}</td>
                                <td>{row.hours}</td>
                            </tr>)}
                            <tr>
                                <td colSpan={2} className="text-end"><strong>Total:</strong></td>
                                <td style={{borderTop: '2px solid black'}}><strong>{totalHours(results).totalBudgeted}</strong></td>
                                <td style={{borderTop: '2px solid black'}}><strong>{totalHours(results).totalAdjusted}</strong></td>
                                <td style={{borderTop: '2px solid black'}}><strong>{totalHours(results).totalReal}</strong></td>
                            </tr>
                        </tbody>
                    </table>
                :
                <LoadingSpinner size={75}/>
                }
                </>
            :null}
        </div>
    )
}

export default WorkOrderReconciliation;