import React, { useState } from 'react';

function Login() {
    const [btnIcon, setBtnIcon] = useState("fas fa-arrow-right");
    const [btnLabel, setBtnLabel] = useState("Log In");
    const [btnDisabled, setBtnDisabled] = useState(false);

    const logIn = (event) => {
        event.preventDefault();
        setBtnIcon("fas fa-spinner");
        setBtnLabel("Logging in...");
        setBtnDisabled(true);
        window.open('/auth/azuread', '_self');
    }

    return(
        <div className="container">
            <center className="mt-5">
                <img src="logo559x229.png" width={250}/>
                <h1 className="mt-1">Timecard System</h1>
                <button className="btn btn-lg btn-primary mt-1" onClick={logIn} disabled={btnDisabled}>
                    <i className={btnIcon}/>
                    &nbsp;{btnLabel}
                </button>
                <p className="text-muted mt-3"><i>
                    By signing in and using the Timecard System to
                    enter and submit timecard information,
                    you are certifying the information is accurate
                    to the best of your knowledge.
                    Clicking the "Submit" button will digitally sign
                    your timecard. Once submitted, your timecard can
                    only be edited by contacting your manager.
                </i></p>
            </center>
        </div>
    )
}

export default Login;