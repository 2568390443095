import React, {useState, useEffect} from 'react';

import helpers from '../helpers';
import api from '../api';
import LoadingSpinner from './LoadingSpinner';

const HoursReportTableRow = ({report}) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const dateStr = (strDate) => {
        const date = helpers.dateFromISOFragment(strDate);
        return date.toLocaleDateString();
    }

    const handleDownload = (event) => {
        event.preventDefault();
        setIsDownloading(true);
        api.getHoursReportBlobById(report._id)
        .then(blob => {
            helpers.downloadBlob(blob, `HoursReport_${report.startDate}_${report.endDate}.xlsx`)
        })
        .catch(e => {
            if(e.name !== "AbortError") {
                console.error(e);
                alert("ERROR: Failed to download the Hours Report.");
            }
        })
        .finally(() => {
            setIsDownloading(false);
        })
    }

    return(
        <tr>
            <td>{new Date(report.createdAt).toLocaleDateString()}</td>
            <td>{dateStr(report.startDate)}</td>
            <td>{dateStr(report.endDate)}</td>
            <td>
                <button className="btn btn-success" onClick={handleDownload} disabled={isDownloading}>
                    <i className={isDownloading ? "fas fa-spinner" : "fas fa-download"}/>&nbsp;{isDownloading ? "Downloading..." : "Download"}
                </button>
            </td>
        </tr>
    )
}

const HoursReports = props => {
    const [historicReports, setHistoricReports] = useState(null);
    const [startDate, setStartDate] = useState(helpers.dateToISOFragment(new Date));
    const [endDate, setEndDate] = useState(helpers.dateToISOFragment(new Date));
    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {
        if(historicReports === null) {
            api.getHoursReports()
            .then(reports => {
                setHistoricReports(reports);
            })
            .catch(err => {
                console.error(err);
                alert("ERROR: Failed to read Hours Reports");
            });
        }
    }, [historicReports]);

    const handleChange = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        if(name === "startDate") {
            setStartDate(value);
        }
        else if(name === "endDate") {
            setEndDate(value);
        }
    }

    const handleDownload = async(event) => {
        event.preventDefault();
        setIsDownloading(true);

        api.createHoursReportByRange(startDate, endDate, 'blob')
        .then(blob => {
            helpers.downloadBlob(blob, `HoursReport_${startDate}_${endDate}.xlsx`);
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                alert("ERROR: Failed to download the Hours Report.");
            }
        })
        .finally(() => {
            setIsDownloading(false);
        })
    }

    return (
        <div className="mb-3">
            <div className="accordion" id="historicReportsAccordion">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="historicReportsHeader">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#historicReportsCollapse" aria-expanded="false" aria-controls="historicReportsCollapse">
                            Previous Reports
                        </button>
                    </h2>
                    <div id="historicReportsCollapse" className="accordion-collapse collapse" aria-labelledby="historicReportsHeader" data-bs-parent="#historicReportsAccordion">
                        <div className="accordion-body">
                            {historicReports !== null ? <>
                            {historicReports.length > 0 ? 
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Date Created</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {historicReports.map((report, i) => <HoursReportTableRow key={i} report={report}/>)}
                                </tbody>
                            </table>
                            :<p className="text-muted">Nothing here yet.</p>}</>
                            :<LoadingSpinner size={50}/>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row my-3">
                <div className="col d-flex flex-column align-items-center">
                    <label className="form-label">Start Date:&nbsp;</label>
                    <input className="form-control w-auto" type="date" value={startDate} name="startDate" onChange={handleChange} disabled={isDownloading}/>
                </div>
                <div className="col d-flex flex-column align-items-center">
                    <label className="form-label">End Date:&nbsp;</label>
                    <input className="form-control w-auto" type="date" value={endDate} name="endDate" onChange={handleChange} disabled={isDownloading}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <button className="btn btn-success" onClick={handleDownload} disabled={isDownloading}>
                        <i className={isDownloading ? "fas fa-spinner" : "fas fa-download"}/>&nbsp;{isDownloading ? "Downloading..." : "Download Report"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default HoursReports;