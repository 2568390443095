import React, {useState, useEffect, useContext} from 'react';
import helpers from '../helpers';

import EntriesTable from './EntriesTable';
import LoadingSpinner from './LoadingSpinner';
import UserContext from '../UserContext';
import PrintModal from './PrintModal';
import api from '../api';
import ModalContext from './ModalContext';
import ExportEntriesButton from './ExportEntriesButton';

/**
 * @param {string} props.startDate
 * @param {string} props.endDate
 * @param {object} props.user
 */
function RecordView(props) {
    const [entries, setEntries] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [readonly, setReadonly] = useState(true);
    const userContext = useContext(UserContext);
    const modaling = useContext(ModalContext);

    useEffect(() => {
        setLoaded(false);
        api.getEntriesForUser(props.user.email, props.startDate, props.endDate)
        .then(entries => {
            setEntries(entries.sort((a,b) => a.day < b.day ? -1 : 1));
            setLoaded(true);
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                alert("ERROR: Failed to get the record's entries");
            }
        })
    }, [props.user, props.startDate, props.endDate]);

    const durationStr = () => {
        const firstDay = helpers.dateFromISOFragment(props.startDate);
        let lastDay = helpers.dateFromISOFragment(props.endDate)
        if(firstDay.getFullYear() === lastDay.getFullYear()) {
            if(firstDay.getMonth() === lastDay.getMonth()) {
                return `${helpers.monthStr(firstDay.getMonth())} ${firstDay.getDate()}-${lastDay.getDate()}, ${firstDay.getFullYear()}`;
            }
            else {
                return `${helpers.monthStr(firstDay.getMonth())} ${firstDay.getDate()} - ${helpers.monthStr(lastDay.getMonth())} ${lastDay.getDate()}, ${firstDay.getFullYear()}`;
            }
        }
        else {
            return `${helpers.monthStr(firstDay.getMonth())} ${firstDay.getDate()}, ${firstDay.getFullYear()} - ${helpers.monthStr(lastDay.getMonth())} ${lastDay.getDate()}, ${lastDay.getFullYear()}`;
        }
    }

    const openPrintModal = (event) => {
        event.preventDefault();
        const baseDay = helpers.dateFromISOFragment(props.startDate);
        const modal = <PrintModal dismiss={(event) => {event.preventDefault(); modaling.setModal(null)}} documentTitle={`Timecard_${props.user.first}_${props.user.last}_${baseDay.getFullYear()}_${helpers.monthStr(baseDay.getMonth())}`}>
            <EntriesTable entries={entries} showDay readonly showTotal/>
        </PrintModal>
        modaling.setModal(modal);
    }

    const privilegedAdmins = ["austin@gocbit.com", "justin@gocbit.com", "jaden@gocbit.com", "kristia@gocbit.com"];

    return (
        <>
            <div className="row">
                <p className="fs-4 px-0">{durationStr()}</p>
            </div>
            {loaded ?
            <>
            <button className="btn btn-dark w-fit me-2" onClick={openPrintModal}>
                <i className="fas fa-print"/>&nbsp;Print
            </button>
            <ExportEntriesButton entries={entries} filename={`Entries_${props.user.first}_${props.user.last}_${helpers.dateFromISOFragment(props.startDate).getFullYear()}_${helpers.monthStr(helpers.dateFromISOFragment(props.startDate).getMonth())}`}/>
            {
                privilegedAdmins.includes(userContext.loggedInUser.email) ?
                <div className="row my-2">
                    <div className="col">
                        <div className="form-check">
                            <input id="enableEditingCheckbox" type="checkbox" className="form-check-input" checked={!readonly} onChange={(_) => {setReadonly(!readonly)}}/>
                            <label className="form-check-label" htmlFor="enableEditingCheckbox">
                                Enable Editing
                            </label>
                        </div>
                    </div>
                </div>
            :null}
            <div className="row">
                {entries.length === 0 ? 
                    <p className="fs-3">
                        <i>
                            There are no entries for this month by this user.
                        </i>
                    </p>
                :null}
            </div>
            <div className="row">
                <EntriesTable forUser={props.user} entries={entries} setEntries={setEntries} showDay readonly={readonly} showTotal/>
            </div>
            </>
            :
            <LoadingSpinner size="50px"/>
            }
        </>
    )
}

export default RecordView;