import React, { useState, useEffect } from 'react';

import helpers from '../helpers';
import LoadingSpinner from './LoadingSpinner';
import api from '../api';
import EntriesTable from './EntriesTable';

function HoursForUser(props) {
    const [hours, setHours] = useState({
        hmis: 0,
        cbit: 0,
        sick: 0,
        vacation: 0,
        holidayHMIS: 0,
        holidayCBIT: 0,
        ot: 0,
        ocw: 0
    });
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
        api.getUsersHoursInRange(props.user.email, props.startDate, props.endDate)
        .then((response) => {
            setHours(props.useAdjusted ? response.adjustedHours : response.hours);
            setLoaded(true);
        })
        .catch(err => {
            console.error(err);
            alert("ERROR: Failed to retrieve hours. Please refresh and try again. Alert the website administrator if the problem persists.");
        })
    }, [props.startDate, props.endDate]);

    const hoursHMIS = () => {
        if(hours.holidayHMIS > 0) {
            return `${hours.hmis.toFixed(2)} + ${hours.holidayHMIS.toFixed(2)} Holiday Hours = ${(hours.hmis + hours.holidayHMIS).toFixed(2)}`;
        }
        else {
            return hours.hmis.toFixed(2);
        }
    }

    const hoursCBIT = () => {
        let str = `${hours.cbit.toFixed(2)}`;
        if(hours.ot > 0) {
            str += ` + ${hours.ot.toFixed(2)} OT Hours`;
        }
        if(hours.holidayCBIT > 0) {
            str += ` + ${hours.holidayCBIT.toFixed(2)} Holiday Hours`;
        }
        if(hours.ot > 0 || hours.holidayCBIT > 0) {
            str += ` = ${(hours.cbit + hours.ot + hours.holidayCBIT).toFixed(2)}`;
        }
        return str;
    }

    return (<tr> 
        <td>
            {props.user.first} {props.user.last}
        </td>
        <td className="text-end">
            {loaded ? <>{hoursHMIS()}</> : <i className="fas fa-spinner"/>}
        </td>
        <td className="text-end">
            {loaded ? <>{hoursCBIT()}</> : <i className="fas fa-spinner"/>}
        </td>
        <td className="text-end">
            {loaded ? <>{hours.sick.toFixed(2)}</> : <i className="fas fa-spinner"/>}
        </td>
        <td className="text-end">
            {loaded ? <>{hours.vacation.toFixed(2)}</> : <i className="fas fa-spinner"/>}
        </td>
        <td className="text-end">
            {loaded ? <>{hours.ocw.toFixed(2)}</> : <i className="fas fa-spinner"/>}
        </td>
    </tr>
    )
}

function HoursForPeriod(props) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [users, setUsers] = useState(null);
    const [otEntries, setOtEntries] = useState(null);

    useEffect(() => {
        if(otEntries === null && users) {
            api.getEntriesInRange(startDate, endDate)
            .then(entries => {
                setOtEntries(entries.filter(entry => includeUser(entry.user) && entry.type === "OT"));
            })
            .catch(err => {
                console.error(err);
                alert("ERROR: Failed to retrieve OT Entries. Please refresh and try again. Alert the website administrator if the problem persists.");
            })
        }
    }, [otEntries, users]);

    useEffect(() => {
        setOtEntries(null);
    }, [startDate, endDate]);

    useEffect(() => {
        if(startDate === null) {
            const today = new Date();
            const todayStr = helpers.dateToISOFragment(today);
            const tokens = todayStr.split('-');
            const firstOfTheMonth = `${tokens[0]}-${tokens[1]}-01`;
            setStartDate(firstOfTheMonth);
        }
    }, [startDate]);

    useEffect(() => {
        if(endDate === null) {
            const today = new Date();
            setEndDate(helpers.dateToISOFragment(today));
        }
    }, [endDate]);

    useEffect(() => {
        if(users === null) {
            api.getUsers()
            .then(users => {
                setUsers(users);
            })
            .catch(err => {
                console.error(err);
                alert("Failed to retrieve users. Please refresh and try again. Alert the website administrator if the problem persists.");
            });
        }
    }, [users]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "startDate") {
            if(value > endDate) {
                setStartDate(endDate);
            }
            else {
                setStartDate(value);
            }
        }
        else if(name === "endDate") {
            if(value < startDate) {
                setEndDate(startDate);
            }
            else {
                setEndDate(value);
            }
        }
    }

    const includeUser = (user) => {
        const excludedUsers = ['austin@gocbit.com'];
        return !excludedUsers.includes(user.email)
    }

    const loaded = startDate && endDate && users !== null && otEntries !== null;

    let rows = [];
    if(loaded) {
        for(const user of users) {
            if(includeUser(user)) {
                rows.push(<HoursForUser user={user} startDate={startDate} endDate={endDate}/>)
            }
        }
    }

    return(
        <div className="text-start">
            <div className="row">
                <div className="col">
                    <label className="form-label">Start Date:&nbsp;</label>
                    <input className="form-control w-auto" type="date" value={startDate} name="startDate" onChange={handleChange}/>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col">
                    <label className="form-label">End Date:&nbsp;</label>
                    <input className="form-control w-auto" type="date" value={endDate} name="endDate" onChange={handleChange}/>
                </div>
            </div>
            <div className="row mt-3">
                {loaded ?
                    <>
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th className="text-end">HMIS Hours</th>
                                <th className="text-end">CBIT Hours</th>
                                <th className="text-end">Sick Hours</th>
                                <th className="text-end">Vacation Hours</th>
                                <th className="text-end">OCW Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                    {otEntries !== null && otEntries.length > 0 ? <EntriesTable entries={otEntries} showDay readonly showTotal showUser/>
                    :null}
                    </>
                : 
                    <LoadingSpinner size={100}/>
                }
            </div>
        </div>
    )
}

export default HoursForPeriod;