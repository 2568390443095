import React, { useState, useEffect } from 'react';

function QBChecker(props) {
    const [status, setStatus] = useState('LOADING') // `OK`, `LOADING`, `AUTHURI`, `ERROR`

    const getStatus = () => {
        setStatus('LOADING');
        return new Promise((resolve, reject) => {
            fetch('/qb/token')
            .then(async response => {
                if(response.status === 200) {
                    resolve({status: 'OK', code: 200, message: 'OK'})
                }
                else if(response.status === 302) {
                    const text = await response.text();
                    if(text && text.startsWith('https')) {
                        resolve({status: 'AUTHURI', code: response.status, message: `authURI: ${text}`});
                    }
                    else {
                        reject({status: 'ERROR', code: response.status, message: 'No authURI given'});
                    }
                }
                else {
                    if(response.status === 401) {
                        resolve({status: 'ERROR', code: response.status, message: 'Your session has expired. The page will automatically refresh and you can sign back in.'});
                    }
                    else {
                        resolve({status: 'ERROR', code: response.status, message: "Error authenticating QuickBooks."})
                    }
                }
            })
            .catch(err => {
                reject(err);
            })
        })
    }

    useEffect(() => {
        getStatus()
        .then(response => {
            setStatus(response.status);
            if(response.code === 401) {
                alert(response.message);
                window.open('/', '_self');
            }
            else if(response.status === 'ERROR') {
                console.error(response);
                setStatus('ERROR');
            }
            else if(response.status === 'AUTHURI') {
                console.log(response.message);
                setStatus('AUTHURI');
            }
        })
        .catch(err => {
            setStatus('ERROR');
            console.error(err);
            if(err.code === 302) {
                alert("QuickBooks authentication error: No authURI given. Please notify the site administrator.");
            }
            else {
                alert("An unhandled error has occurred when authenticating QuickBooks. QuickBooks operations are not available. Please notify the site administrator.");
            }
        })
    }, []);

    const icon = () => {
        let text = "";
        let classes = "";
        switch(status) {
            case 'OK':
                classes = "fa-check text-success";
                text = "QuickBooks operations are available";
                break;
            case 'LOADING':
                classes = "fa-spinner";
                text = "Authenticating QuickBooks, please wait...";
                break;
            case 'AUTHURI':
                classes = "fa-triangle-exclamation text-warning";
                text = "QuickBooks operations are unavailable. The authURI has been printed to the console. Ask the site administrator for assistance.";
                break;
            case 'ERROR':
                classes = "fa-times text-danger";
                text = "QuickBooks operations are unavailable. Please notify the site administrator.";
                break;
            default:
                text = "Something weird just happened... please notify the site administrator.";
                break;
        }
        return (
            <div className="tooltip-container border-0">
                <span className="tooltip-text-bottom">
                    {text}
                </span>
                {classes ? <i className={`fas ${classes}`}/> : '???'}
            </div>
        )
    }

    return(
        <span>QB Status: {icon()}</span>
    )
}

export default QBChecker;