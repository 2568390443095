import React, {useState, useEffect, useContext} from 'react';

import api from '../api';
import ModalContext from './ModalContext';
import Modal, {choiceCancel, choiceDelete} from './Modal';
import helpers from '../helpers';
import FormField from './FormField';
import LoadingSpinner from "./LoadingSpinner";

const DEFAULT_POSITION = {
    positionTitle: '',
    qbItem: {
        id: '',
        name: '',
        fullyQualifiedName: ''
    },
    hoursBudget: 0,
    rate: 0,
    users: []
}

const composeUsersList = (assignedUsers, allUsers) => {
    let usersList = [];
    if(!allUsers) return usersList;
    for(const assignedUser of assignedUsers) {
        for(const user of allUsers) {
            if(user.email === assignedUser) {
                usersList.push({
                    first: user.first,
                    last: user.last,
                    email: user.email
                })
                break;
            }
        }
    }
    return usersList;
}

const PositionForm = ({position, allUsers, index, setPositionAtIndex, deletePositionAtIndex, allPositions, isSaving}) => {
    const [isVerifyingQbItem, setIsVerifyingQbItem] = useState(false);
    const [assignedUsers, setAssignedUsers] = useState(position ? position.users.map((user) => user.email) : []);

    useEffect(() => {
        let newPosition = JSON.parse(JSON.stringify(position));
        newPosition.users = composeUsersList(assignedUsers, allUsers);
        setPositionAtIndex(index, newPosition);
    }, [assignedUsers, allUsers]);

    const addUser = (event) => {
        event.preventDefault();
        setAssignedUsers([...assignedUsers, "NOT SET"]);
    }

    const deleteUser = (index) => {
        setAssignedUsers(assignedUsers.filter((_, i) => i !== index));
    }

    const changeUser = (index, user) => {
        setAssignedUsers(assignedUsers.map((assignedUser, i) => i === index ? user : assignedUser));
    }

    const handleChange = (event) => {
        event.preventDefault();
        let newPosition = JSON.parse(JSON.stringify(position));
        const name = event.target.name;
        const value = event.target.value;
        if(name === "qbItemName") {
            newPosition.qbItem.name = value;
        }
        else if(name.startsWith('user')) {
            const tokens = name.split('user');
            const index = parseInt(tokens[1]);
            changeUser(index, value);
        }
        else {
            newPosition[name] = value;
        }
        setPositionAtIndex(index, newPosition);
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const verifyQbItem = (event) => {
        event.preventDefault();
        setIsVerifyingQbItem(true);
        api.getQbItemsLike(position.qbItem.name)
        .then((array) => {
            const item = array[0];
            let newPosition = JSON.parse(JSON.stringify(position));
            newPosition.qbItem.fullyQualifiedName = item.FullyQualifiedName;
            newPosition.qbItem.id = item.Id;
            setPositionAtIndex(index, newPosition);
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                alert("ERROR: Failed to verify QB Item");
            }
        })
        .finally(() => {
            setIsVerifyingQbItem(false);
        })
    }

    const filteredUserOptions = (assignedUser) => {
        return allUsers.filter((user) => {
            if(user.email === assignedUser) return true;
            let alreadyAssignedUser = false;
            for(const position of allPositions) {
                for(const posUser of position.users) {
                    if(posUser.email === user.email) {
                        alreadyAssignedUser = true;
                        break;
                    }
                }
            }
            if(!alreadyAssignedUser) {
                alreadyAssignedUser = assignedUsers.includes(user.email);
            }
            return !alreadyAssignedUser;
        })
    }

    return (
        <div className="section-outline">
            <div className="row">
                <div className="col">
                    <button className="btn btn-danger float-end" onClick={(e) => {e.preventDefault(); deletePositionAtIndex(index)}}>
                        <i className="fas fa-times"/>&nbsp;Remove Position
                    </button>
                </div>
            </div>
            <FormField
                type="text"
                name="positionTitle"
                label="Position Title"
                value={position.positionTitle}
                description="The title of the position associated with the Work Order. Used only as an identifier; the QB Item Name is what's used on invoices."
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={isSaving}
                required
            />
            <FormField
                type="number"
                name="rate"
                label="Rate"
                value={position.rate}
                description="The rate used in invoicing. (Note: This overrides the rate set for the QuickBooks Item.)"
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={isSaving}
                required
            />
            <FormField
                type="number"
                name="hoursBudget"
                label="Hours Budget"
                value={position.hoursBudget}
                description="The budget of hours not to be exceeded by users associated with this Work Order within the given date range."
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={isSaving}
                required
            />
            <FormField
                type="text"
                name="qbItemName"
                label="QuickBooks Item Name"
                value={position.qbItem.name}
                description={"The name of the QuickBooks \"Products & Services\" Item associated with labor related to this Work Order. This name is used by the button below to perform a lookup and acquire the Item's ID, which is what is ultimately used for API communication with QuickBooks. Customarily, the Item name should match the Position Title."}
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={isSaving}
                required
            />
            <div className="row">
                <div className="col">
                    <button className="btn btn-primary" onClick={verifyQbItem} disabled={isVerifyingQbItem}>
                        <i className={isVerifyingQbItem ? 'fas fa-spinner' : 'fas fa-magnifying-glass'}/>&nbsp;{isVerifyingQbItem ? "Verifying..." : "Verify QuickBooks Item"}
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <strong>QuickBooks Item Confirmation:</strong>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {position.qbItem.id ? 
                        <span>{`${position.qbItem.fullyQualifiedName === "" ? "(undefined)" : position.qbItem.fullyQualifiedName} (ID: ${position.qbItem.id})`}</span>
                    :
                        <span className="text-muted">(Not yet verified)</span>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <strong>Users:</strong>
                </div>
            </div>
            {allUsers ? 
            <>
            <div className="row">
                <div className="col">
                    <button className="btn btn-success" onClick={addUser}>
                        <i className="fas fa-plus"/>&nbsp;Add User
                    </button>
                </div>
            </div>
            {assignedUsers.map((assignedUser, i) => 
                <div className="row mt-2" key={i}>
                    <div className="col d-flex flex-row align-items-center justify-content-center">
                        <select className="form-select w-auto" name={`user${i}`} onChange={handleChange} disabled={isSaving} value={assignedUsers[i]}>
                            <option value="NOT SET">--NOT SET--</option>
                            {filteredUserOptions(assignedUser).map((user, i) => 
                                <option key={i} value={user.email}>{user.first} {user.last}</option>
                            )}
                        </select>
                        <button className="ms-2 btn btn-danger btn-sm" onClick={(event) => {event.preventDefault(); deleteUser(i)}} disabled={isSaving}>
                            <i className="fas fa-times"/>
                        </button>
                    </div>
                </div>
            )}
            </>
            : <LoadingSpinner size={50}/>}
        </div>
    )
}

const WorkOrderEntryForm = props => {
    const [mainContractNumber, setMainContractNumber] = useState(props.workOrder ? props.workOrder.mainContractNumber : "");
    const [subcontractNumber, setSubcontractNumber] = useState(props.workOrder ? props.workOrder.subcontractNumber : "");
    const [workOrderNumber, setWorkOrderNumber] = useState(props.workOrder ? props.workOrder.workOrderNumber : "");
    const [startDate, setStartDate] = useState(props.workOrder ? props.workOrder.startDate : helpers.dateToISOFragment(new Date()));
    const [endDate, setEndDate] = useState(props.workOrder ? props.workOrder.endDate : helpers.dateToISOFragment(new Date()));
    const [positions, setPositions] = useState(props.workOrder ? props.workOrder.positions : [DEFAULT_POSITION]);
    const [allUsers, setAllUsers] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if(allUsers === null) {
            api.getUsers()
            .then(users => setAllUsers(users))
            .catch(err => {
                console.error(err);
                alert("ERROR: Failed to read Users. Will not be able to assign users to the Work Order.");
            })
        }
    }, [allUsers]);

    const setPositionAtIndex = (index, newPosition) => {
        let newPositions = positions.map((pos, i) => {
            if(i !== index) return pos;
            else return newPosition
        })
        setPositions(newPositions);
    }

    const handleChange = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        props.setSaved(false);
        switch(name){
            case 'mainContractNumber':
                setMainContractNumber(value);
                break;
            case 'subcontractNumber':
                setSubcontractNumber(value);
                break;
            case 'workOrderNumber':
                setWorkOrderNumber(value);
                break;
            case 'startDate':
                setStartDate(value > endDate ? endDate : value);
                break;
            case 'endDate':
                setEndDate(value < startDate ? startDate : value);
                break;
            default:
                break;
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSaving(true);
        let body = {
            mainContractNumber: mainContractNumber,
            subcontractNumber: subcontractNumber,
            workOrderNumber: workOrderNumber,
            startDate: startDate,
            endDate: endDate,
            positions: positions
            /** 
            positionTitle: positionTitle,
            qbItem: {
                id: qbItemId,
                name: qbItemName,
                fullyQualifiedName: qbFullyQualifiedItemName
            },
            hoursBudget: hoursBudget,
            rate: rate,
            users: composeUsersList()
            */
        }
        if(props.workOrder && !props.duplicate) {
            api.updateWorkOrderEntry(props.workOrder._id, body)
            .then(_ => {
                alert("Saved successfully!");
                props.setSaved(true);
            })
            .catch(err => {
                if(err.name !== "AbortError") {
                    console.error(err);
                    alert("ERROR: An error occurred and the Work Order could not be saved.");
                }
            })
            .finally(() => {
                setIsSaving(false);
            })
        }
        else {
            api.createWorkOrderEntry(body)
            .then(_ => {
                alert("Saved successfully!");
                props.setSaved(true);
            })
            .catch(err => {
                if(err.name !== "AbortError") {
                    console.error(err);
                    alert("ERROR: An error occurred and the Work Order could not be saved.");
                }
            })
            .finally(() => {
                setIsSaving(false);
            })
        }
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const addPosition = (event) => {
        event.preventDefault();
        setPositions([...positions, DEFAULT_POSITION]);
    }

    const deletePositionAtIndex = (index) => {
        setPositions(positions.filter((_, i) => i !== index));
    }

    return (<div>
        <h3>New Work Order Entry</h3>
        <form id="workOrderEntryForm" onSubmit={handleSubmit} noValidate>
            <FormField
                type="text"
                name="mainContractNumber"
                label="Main Contract Number"
                value={mainContractNumber}
                description="The contact number of the main contract this Work Order falls under."
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={isSaving}
                required
            />
            <FormField
                type="text"
                name="subcontractNumber"
                label="Subcontract Number"
                value={subcontractNumber}
                description="The contact number of the subcontract this Work Order falls under."
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={isSaving}
                required
            />
            <FormField
                type="text"
                name="workOrderNumber"
                label="Work Order Number"
                value={workOrderNumber}
                description="The number of this Work Order. It is conventionally a derived from the subcontract number, but with a 'WO' prefix before the final 3 numbers."
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={isSaving}
                required
            />
            <FormField
                type="date"
                name="startDate"
                label="Start Date"
                value={startDate}
                description="The lower-bound of the date range for which the Work Order is applicable."
                handleChange={handleChange}
                disabled={isSaving}
                required
            />
            <FormField
                type="date"
                name="endDate"
                label="End Date"
                value={endDate}
                description="The upper-bound of the date range for which the Work Order is applicable."
                handleChange={handleChange}
                disabled={isSaving}
                required
            />
            <div className="row">
                <div className="col">
                    <strong>Positions:</strong>
                </div>
            </div>
            {positions.map((pos, i) => <div>
                <div className="row my-2">
                    <div className="col">
                        <PositionForm position={pos} allUsers={allUsers} index={i} setPositionAtIndex={setPositionAtIndex} isSaving={isSaving} deletePositionAtIndex={deletePositionAtIndex} allPositions={positions}/>
                    </div>
                </div>
            </div>)}
            <div className="row">
                <div className="col">
                    <button className="btn btn-success" onClick={addPosition}>
                        <i className="fas fa-plus"/>&nbsp;Add Position
                    </button>
                </div>
            </div>
            <button className="mt-2 btn btn-primary" type="submit">
                <i className={isSaving ? "fas fa-spinner" : "fas fa-floppy-disk"}/>&nbsp;{isSaving ? "Saving..." : "Save"}
            </button>
        </form>
    </div>)
}

const WorkOrderEntryModal = props => {
    const [saved, setSaved] = useState(props.workOrder ? true : false);
    const modaling = useContext(ModalContext);
    const choices = [
        choiceCancel({modalContext: modaling, backtrack: true, onClose: props.onClose}, !saved, <div>
            <h3>Close without Saving?</h3>
            <p>You have unsaved changes to this Work Order. Are you sure you want to close it without saving?</p>
        </div>, "Close")
    ]
    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <WorkOrderEntryForm setSaved={setSaved} workOrder={props.workOrder} duplicate={props.duplicate}/>
        </Modal>
    )
}

const WorkOrderEntries = props => {
    const [workOrders, setWorkOrders] = useState(null);
    const modaling = useContext(ModalContext);
    
    useEffect(() => {
        if(workOrders === null) {
            api.getWorkOrderEntries()
            .then(entries => setWorkOrders(entries))
            .catch(e => {
                if(e.name !== "AbortError") {
                    console.error(e);
                    alert("ERROR: Failed to read Work Order Entries");
                }
            })
        }
    }, [workOrders]);

    const onModalClose = () => {
        setWorkOrders(null); // Performs refresh
    }

    const addNewEntry = (event) => {
        event.preventDefault();
        modaling.setModal(<WorkOrderEntryModal onClose={onModalClose}/>);
    }

    const openWorkOrderInModal = (workOrder) => {
        modaling.setModal(<WorkOrderEntryModal workOrder={workOrder} onClose={onModalClose}/>);
    }

    const duplicateWorkOrder = (workOrder) => {
        modaling.setModal(<WorkOrderEntryModal workOrder={workOrder} onClose={onModalClose} duplicate/>);
    }

    const confirmDeleteWorkOrder = (workOrder) => {
        const choices = [
            choiceCancel({modalContext: modaling, backtrack: true}),
            choiceDelete({modalContext: modaling}, () => {
                api.deleteWorkOrderEntry(workOrder._id)
                .then(_ => {
                    modaling.backtrack();
                    onModalClose();
                })
                .catch(err => {
                    if(err.name !== "AbortError") {
                        console.error(err);
                        alert("ERROR: Failed to delete the Work Order");
                    }
                })
            }, {noConfirm: true})
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Are you sure?</h3>
            <p>This action will permanently delete Work Order number {workOrder.workOrderNumber} ({workOrder.positions.map(pos => pos.positionTitle).join('; ')}).</p>
        </Modal>
        modaling.setModal(modal);
    }

    const usersInOrder = (order) => {
        let users = [];
        for(const position of order.positions) {
            for(const posUser of position.users) {
                if(!users.find((user) => user.email === posUser.email)) {
                    users.push(posUser)
                }
            }
        }
        return users.map(user => `${user.first} ${user.last}`).join(', ');
    }

    return(
        <div>
            <h3>Work Order Entries</h3>
            <div className="row">
                <div className="col">
                    <button className="btn btn-success" onClick={addNewEntry}>
                        <i className="fas fa-plus"/>&nbsp;Add Work Order Entry
                    </button>
                </div>
            </div>
            {workOrders !== null ?
                <>
                {workOrders.length > 0 ? 
                    <table className="table table-striped table-bordered mt-3">
                        <thead>
                            <tr>
                                <th>Work Order Number</th>
                                <th>Position Title(s)</th>
                                <th>Users</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {workOrders.map((workOrder, i) => <tr key={i}>
                                <td>{workOrder.workOrderNumber}</td>
                                <td>{workOrder.positions.map(pos => pos.positionTitle).join(', ')}</td>
                                <td>{usersInOrder(workOrder)}</td>
                                <td>{helpers.dateFromISOFragment(workOrder.startDate).toLocaleDateString()}</td>
                                <td>{helpers.dateFromISOFragment(workOrder.endDate).toLocaleDateString()}</td>
                                <td>
                                    <button className="btn btn-sm btn-secondary me-2" onClick={(event) => {event.preventDefault(); openWorkOrderInModal(workOrder)}}>
                                        <i className="fas fa-pencil"/>
                                    </button>
                                    <button className="btn btn-sm btn-secondary me-2" onClick={(event) => {event.preventDefault(); duplicateWorkOrder(workOrder)}}>
                                        <i className="fas fa-clone"/>
                                    </button>
                                    <button className="btn btn-sm btn-danger" onClick={(event) => {event.preventDefault(); confirmDeleteWorkOrder(workOrder)}}>
                                        <i className="fas fa-times"/>
                                    </button>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                : <p className="text-muted">There's nothing here yet.</p>}
                </>
            : <LoadingSpinner size={50}/>}
        </div>
    )
}

export default WorkOrderEntries;