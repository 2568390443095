import React, { useState, useEffect } from 'react';

import LoadingSpinner from './LoadingSpinner';
import api from '../api';

const HolidayRow = props => {

    const handleChange = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        if(name === "name") {
            props.setHoliday({name: value, date: props.holiday.date});
        }
        else if(name === "date") {
            props.setHoliday({name: props.holiday.name, date: value});
        }
    }

    return(
        <tr>
            <td><input name="name" value={props.holiday.name} className="form-control" type="text" placeholder="Ex: Christmas Day" onChange={handleChange}/></td>
            <td><input name="date" value={props.holiday.date} className="form-control" type="date" onChange={handleChange}/></td>
            <td>
                <button className="btn btn-danger" onClick={props.deleteHoliday}>
                    <i className="fas fa-times"/>
                </button>
            </td>
        </tr>
    )
}

const HolidaysInterface = props => {
    const [saving, setSaving] = useState(false);
    const [holidays, setHolidays] = useState(null);

    useEffect(() => {
        if(holidays === null) {
            api.getHolidays()
            .then(holidays => {
                setHolidays(holidays);
            })
            .catch(err => {
                console.error(err);
                alert("ERROR: Failed to read Holidays");
            })
        }
    }, [holidays]);

    const addRow = (event) => {
        event.preventDefault();
        setHolidays([...holidays, {name: "", date: ""}]);
    }

    const setHolidayAtIndex = (index, value) => {
        let newHolidays = JSON.parse(JSON.stringify(holidays));
        newHolidays[index] = value;
        setHolidays(newHolidays);
    }

    const deleteHolidayAtIndex = (index) => {
        setHolidays(holidays.filter((_, i) => i !== index));
    }

    const handleSave = (event) => {
        event.preventDefault();
        setSaving(true);

        api.updateHolidays(holidays)
        .then(savedHolidays => setHolidays(savedHolidays))
        .catch(err => {
            console.error(err);
            alert("ERROR: Failed to save Holidays");
        })
        .finally(() => {
            setSaving(false);
        })
    }

    return(
        <>
        {holidays === null ? <LoadingSpinner size={75}/> : 
            <>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Holiday Name</th>
                        <th>Observance Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {holidays.map((holiday, i) => <HolidayRow holiday={holiday} setHoliday={(holiday) => {setHolidayAtIndex(i, holiday)}} deleteHoliday={(event) => {event.preventDefault(); deleteHolidayAtIndex(i)}}/>)}
                </tbody>
            </table>
            <div className="row mb-2">
                <div className="col">
                    <button className="btn btn-success" onClick={addRow}>
                        <i className="fas fa-plus"/>
                    </button>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <button className="btn btn-primary" onClick={handleSave}>
                        <i className={saving ? "fas fa-spinner" : "fas fa-floppy-disk"}/>&nbsp;{saving ? "Saving..." : "Save Holidays"}
                    </button>
                </div>
            </div>
            </>
        }
        </>
    )
}

export default HolidaysInterface;