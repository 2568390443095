import React, { useState, useEffect, useRef } from 'react';
import helpers from '../helpers';
import JSZip from 'jszip';

/**
 * 
 * @param {Array<Object>} files An array of objects with `blob` and `filename` parameters
 * @param {String} zipName the name of the .zip file when downloading in bulk
 * @param {Any} id A unique value differentiating this BlobDownloadList from any others on the screen.
 */
const BlobDownloadList = ({files, zipName, id}) => {
    const [selectedIndices, setSelectedIndices] = useState([]);
    const selectAll = useRef(null);

    useEffect(() => {
        if(selectAll) {
            selectAll.current.indeterminate = selectedIndices.length !== files.length && selectedIndices.length > 0;
        }
    }, [selectedIndices]);

    const toggleIndex = (index) => {
        if(selectedIndices.includes(index)) {
            // remove it
            setSelectedIndices(selectedIndices.filter(i => i !== index));
        }
        else {
            // add it
            setSelectedIndices([...selectedIndices, index]);
        }
    }

    const blobToBase64 = (blob, callback) => {
        var reader = new FileReader();
        reader.onload = function() {
            var dataUrl = reader.result;
            var base64 = dataUrl.split(',')[1];
            callback(base64);
        };
        reader.readAsDataURL(blob);
    }

    const uniqueFileName = (filename, filenameOccurrences) => {
        if(filenameOccurrences[filename] === 1 || filenameOccurrences[filename] === 0 || filenameOccurrences[filename] === undefined) return filename;
        const baseName = filename.substring(0, filename.indexOf('.'));
        const extension = filename.substring(filename.indexOf('.'));
        return `${baseName} (${filenameOccurrences[filename] - 1})${extension}`;
    }

    const downloadZip = () => {
        const zip = new JSZip();
        const filenameOccurrences = {};
        for(const index of selectedIndices) {
            if(filenameOccurrences[files[index].filename] === undefined) filenameOccurrences[files[index].filename] = 0;
            blobToBase64(files[index].blob, (binaryData) => {
                filenameOccurrences[files[index].filename] += 1;
                zip.file(uniqueFileName(files[index].filename, filenameOccurrences), binaryData, {base64: true});
                if(index === selectedIndices[selectedIndices.length - 1]) {
                    // Download
                    zip.generateAsync({type: 'blob'})
                    .then(blob => {
                        helpers.downloadBlob(blob, zipName);
                    })
                    .catch(err => {
                        console.error(err);
                        alert("ERROR: Failed to generate .zip file");
                    })
                }
            })
        }
    }

    const handleChange = (event) => {
        if(event.target.name === `selectAll${id}`){
            if(selectedIndices.length === files.length) {
                // Uncheck all
                setSelectedIndices([]);
            }
            else {
                // Check all
                setSelectedIndices(files.map((_, i) => i));
            }
        }
    }

    return (
        <div className="row text-start">
            <div className="col d-flex flex-column">

                <div className="form-check">
                    <input className="form-check-input" name={`selectAll${id}`} id={`selectAll${id}`} type="checkbox" onChange={handleChange} ref={selectAll} checked={selectedIndices.length === files.length}/>
                    <label htmlFor={`selectAll${id}`}>
                        <span>Select All</span>
                        {selectedIndices.length > 0 ? <span className="ms-5">
                            Download Selected:&nbsp;
                            <button className="btn btn-sm btn-success" onClick={downloadZip} disabled={selectedIndices.length === 0}>
                                <i className="fas fa-download"/>
                            </button>
                        </span>
                        : null}
                    </label>
                </div>

                {files.map((file, i) => <>
                <div className="form-check" key={i}>
                    <input className="form-check-input" type="checkbox" checked={selectedIndices.includes(i)} id={`blobCheck${i}${id}`} onChange={(_) => {toggleIndex(i)}}/>
                    <label htmlFor={`blobCheck${i}${id}`}>
                        <button className={`btn btn-success w-fit${i !== 0 ? ' mt-1' : ''}`} onClick={(e) => {e.preventDefault(); helpers.downloadBlob(file.blob, file.filename)}}>
                            <i className="fas fa-download"/>&nbsp;{file.filename}
                        </button>
                    </label>
                </div>
                </>)}
            </div>
        </div>
    )
}

export default BlobDownloadList;