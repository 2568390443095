import React, { useState, useEffect, useContext } from 'react';
import helpers from '../helpers';

import Modal from './Modal';
import PrintModal from './PrintModal';
import TimeActivities from './TimeActivities';
import MonthSelector from './MonthSelector';
import RecordView from './RecordView';
import LoadingSpinner from './LoadingSpinner';
import HoursForPeriod from './HoursForPeriod';
import HolidaysInterface from './HolidaysInterface';
import HoursReports from './HoursReports';
import WorkOrderEntries from './WorkOrderEntries';
import ModalContext from './ModalContext';
import HMISReportsWizard from './HMISReportsWizard';
import api from '../api';
import QBChecker from './QBChecker';
import WorkOrderReconciliation from './WorkOrderReconciliation';

function HMISReportDownloadButton(props) {
    const [btnIcon, setBtnIcon] = useState("fas fa-download");
    const [btnDisabled, setBtnDisabled] = useState(false);

    const handleDownload = (event) => {
        event.preventDefault();
        setBtnDisabled(true);
        setBtnIcon("fas fa-spinner");
        api.createXLSX(props.dataSets)
        .then(blob => {
            helpers.downloadBlob(blob, props.filename);
        })
        .catch(err => {
            console.error(err);
            alert("ERROR: Failed to download file");
        })
        .finally(() => {
            setBtnDisabled(false);
            setBtnIcon("fas fa-download");
        })
    }
    
    return(
        <button className={`btn btn-success w-fit${props.margin ? ' mt-1' : ''}`} onClick={handleDownload} disabled={btnDisabled}>
            <i className={btnIcon}/>&nbsp;{props.filename}
        </button>
    )
}

function HMISReports(props) {
    const [bundles, setBundles] = useState(null);

    useEffect(() => {
        if(bundles === null) {
            api.getHMISReportBundles()
            .then(newBundles => setBundles(newBundles))
            .catch(err => {
                console.error(err);
                alert("ERROR: Failed to read Report Bundles");
            })
        }
    }, [bundles]);

    return(
        <div>
            {bundles ? 
            <div className="accordion" id="masterBundlesAccordion">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="masterBundlesAccordionHeader">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#masterBundlesAccordionHeaderCollapse`} aria-expanded="false" aria-controls={`masterBundlesAccordionHeaderCollapse`}>
                            Show Historic Reports
                        </button>
                    </h2>
                    <div id="masterBundlesAccordionHeaderCollapse" className="accordion-collapse collapse" aria-labelledby='masterBundlesAccordionHeader' data-bs-parent='#masterBundlesAccordionHeader'>
                        <div className="accordion-body">
                            <div className="accordion" id="bundlesAccordion">
                                {bundles.map((bundle, i) => <div key={i}>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={`bundleAccordion${i}`}>
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#bundleAccordion${i}Collapse`} aria-expanded="false" aria-controls={`bundleAccordion${i}Collapse`}>
                                                {bundle.workOrder.number} ({bundle.workOrder.positionTitle}) [From {helpers.dateFromISOFragment(bundle.startDate).toLocaleDateString()} to {helpers.dateFromISOFragment(bundle.endDate).toLocaleDateString()}]
                                            </button>
                                        </h2>
                                        <div id={`bundleAccordion${i}Collapse`} className="accordion-collapse collapse" aria-labelledby={`bundleAccordion${i}`} data-bs-parent={`#bundleAccordion${i}`}>
                                            <div className="accordion-body">
                                                <div className="row">
                                                    <div className="col d-flex flex-column">
                                                        <span><strong>QB Invoice:</strong>&nbsp;<a href={`https://qbo.intuit.com/app/invoice?txnId=${bundle.qbInvoice.id}`} target="_blank" rel="noopener noreferrer">Invoice {bundle.qbInvoice.docNumber}</a></span>
                                                        <HMISReportDownloadButton filename={bundle.reports.laborSummaryHours.filename} dataSets={bundle.reports.laborSummaryHours.dataSets} margin/>
                                                        <HMISReportDownloadButton filename={bundle.reports.laborSummaryAmounts.filename} dataSets={bundle.reports.laborSummaryAmounts.dataSets} margin/>
                                                        <HMISReportDownloadButton filename={bundle.reports.costCollections.filename} dataSets={bundle.reports.costCollections.dataSets} margin/>
                                                        <HMISReportDownloadButton filename={bundle.reports.amountsPerCACN.filename} dataSets={bundle.reports.amountsPerCACN.dataSets} margin/>
                                                        <HMISReportDownloadButton filename={bundle.reports.amountsPerTitle.filename} dataSets={bundle.reports.amountsPerTitle.dataSets} margin/>
                                                        <HMISReportDownloadButton filename={bundle.reports.entryDetailsReport.filename} dataSets={bundle.reports.entryDetailsReport.dataSets} margin/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <LoadingSpinner size={75}/>
            }
        </div>
    )
}

function Admin(props) {
    const [loadedUsers, setLoadedUsers] = useState(false);
    const [users, setUsers] = useState([]);
    const [activeUser, setActiveUser] = useState(null);
    const [baseDayStr, setBaseDayStr] = useState(helpers.dateToISOFragment(new Date()));
    const modaling = useContext(ModalContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        api.getUsers(signal)
        .then(data => {
            setUsers(data);
            setActiveUser(data.length > 0 ? data[0] : null);
            setLoadedUsers(true)
        })
        .catch(e => {
            if(e.name !== "AbortError") {
                console.error(e);
                alert("Failed to retrieve users. Please refresh and try again. Alert the website administrator if the problem persists.");
            }
        })

        return () => {
            controller.abort();
        }
    }, []);

    const handleUserChange = (event) => {
        const selectedEmail = event.target.value;
        for(const user of users) {
            if(user.email === selectedEmail) {
                setActiveUser(user);
            }
        }
    }

    const viewTimeActivities = (event) => {
        event.preventDefault();
        const baseDay = helpers.dateFromISOFragment(baseDayStr);
        const modal = <PrintModal dismiss={(event) => {event.preventDefault(); modaling.setModal(null)}} documentTitle={`Time_Activities_${activeUser.first}_${activeUser.last}_${baseDay.getFullYear()}_${helpers.monthStr(baseDay.getMonth())}`}>
            <TimeActivities user={activeUser} month={baseDay.getMonth()} year={baseDay.getFullYear()}/>
        </PrintModal>
        modaling.setModal(modal);
    }

    const viewAllTimeActivities = (event) => {
        event.preventDefault();
        const baseDay = helpers.dateFromISOFragment(baseDayStr);
        const modal = <PrintModal dismiss={(event) => {event.preventDefault(); modaling.setModal(null)}} documentTitle={`Time_Activities_${baseDay.getFullYear()}_${helpers.monthStr(baseDay.getMonth())}`}>
            {users.map(user => <>
                <TimeActivities user={user} month={baseDay.getMonth()} year={baseDay.getFullYear()}/>
                <div style={{breakAfter: 'always'}}/>
            </>)}
        </PrintModal>
        modaling.setModal(modal);
    }

    const showHoursForPeriod = (event) => {
        event.preventDefault();
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                btnColor: 'secondary',
                func: (event) => {
                    event.preventDefault();
                    modaling.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <HoursForPeriod/>
        </Modal>
        modaling.setModal(modal);
    }

    const showHolidays = (event) => {
        event.preventDefault();
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                btnColor: 'secondary',
                func: (event) => {
                    event.preventDefault();
                    modaling.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <HolidaysInterface/>
        </Modal>
        modaling.setModal(modal);
    }

    const showHoursReports = (event) => {
        event.preventDefault();
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                btnColor: 'secondary',
                func: (event) => {
                    event.preventDefault();
                    modaling.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <HoursReports/>
        </Modal>
        modaling.setModal(modal);
    }

    const showWorkOrderEntries = (event) => {
        event.preventDefault();
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                btnColor: 'secondary',
                func: (event) => {
                    event.preventDefault();
                    modaling.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <WorkOrderEntries/>
        </Modal>
        modaling.setModal(modal);
    }

    const openHMISReports = (event) => {
        event.preventDefault();
        const choicesCancelOnly = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                btnColor: 'secondary',
                func: (event) => {
                    event.preventDefault();
                    modaling.setModal(null);
                }
            },
        ];
        const choices = [
            choicesCancelOnly[0],
            {
                btnInner: <span><i className="fas fa-plus"/>&nbsp;Run New Reports</span>,
                btnColor: 'success',
                func: (event) => {
                    event.preventDefault();
                    modaling.setModal(<Modal choices={choicesCancelOnly} dismiss={choicesCancelOnly[0].func}>
                        <HMISReportsWizard/>
                    </Modal>)
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <HMISReports/>
        </Modal>
        modaling.setModal(modal);
    }

    const openReconciliationReport = (event) => {
        event.preventDefault();
        const choicesCancelOnly = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                btnColor: 'secondary',
                func: (event) => {
                    event.preventDefault();
                    modaling.setModal(null);
                }
            },
        ];
        const modal = <Modal choices={choicesCancelOnly} dismiss={choicesCancelOnly[0].func}>
            <WorkOrderReconciliation/>
        </Modal>
        modaling.setModal(modal);
    }

    const sidebar = () => {
        const month = helpers.dateFromISOFragment(baseDayStr).getMonth();
        return (
            <center>
                <h5>Select User:</h5>
                {loadedUsers ? 
                    <>
                    <div className="mx-2">
                        <select className="form-select" value={activeUser.email} onChange={handleUserChange}>
                            {users.map((user, i) => <option key={i} value={user.email}>
                                {user.first} {user.last}
                            </option>)}
                        </select>
                    </div>
                    <div className="mx-2">
                        <QBChecker/>
                    </div>
                    <div className="mx-2">
                        <button className="btn btn-dark w-100 mt-3" onClick={viewTimeActivities}>
                            <i className="fas fa-file-contract"/>
                            &nbsp; View {activeUser.first}'s Time Activities for {helpers.monthStr(month)}
                        </button>
                        <button className="btn btn-dark w-100 mt-3" onClick={viewAllTimeActivities}>
                            <i className="fas fa-file-contract"/>
                            &nbsp; View All Time Activities for {helpers.monthStr(month)}
                        </button>
                        <button className="btn btn-dark w-100 mt-3" onClick={showHoursForPeriod}>
                            <i className="fas fa-clock"/>
                            &nbsp; Total Hours
                        </button>
                        <button className="btn btn-dark w-100 mt-3" onClick={showHolidays}>
                            <i className="fas fa-calendar"/>
                            &nbsp; Holidays
                        </button>
                        <button className="btn btn-dark w-100 mt-3" onClick={showHoursReports}>
                            <i className="fas fa-chart-line"/>
                            &nbsp; Hours Reports
                        </button>
                        <button className="btn btn-dark w-100 mt-3" onClick={showWorkOrderEntries}>
                            <i className="fas fa-gear"/>
                            &nbsp; Work Orders
                        </button>
                        <button className="btn btn-dark w-100 mt-3" onClick={openReconciliationReport}>
                            <i className="fas fa-file-lines"/>
                            &nbsp; Work Order Reconciliation
                        </button>
                        <button className="btn btn-dark w-100 mt-3" onClick={openHMISReports}>
                            <i className="fas fa-chart-line"/>
                            &nbsp; HMIS Reports
                        </button>
                    </div>
                    </>
                :
                    <LoadingSpinner size="50px"/>
                }
            </center>
        )
    }

    const daysInMonth = helpers.extrapolateDaysInMonth(helpers.dateFromISOFragment(baseDayStr).getFullYear(), helpers.dateFromISOFragment(baseDayStr).getMonth());

    return(
        <div className="container">
            <div className="row">
                {true ? 
                <>
                <div className="d-none d-md-block col-md-2">
                    {sidebar()}
                </div>
                <div className="col-12 col-md-10">
                    <div className="d-block d-md-none">
                        {sidebar()}
                    </div>
                    <div>
                        {loadedUsers ?
                        <>
                        <div className="row">
                            <center>
                                <h2>
                                    {activeUser.first} {activeUser.last}'s Timecard
                                </h2>
                                <h4>
                                    <MonthSelector setBaseDayStr={setBaseDayStr}/>
                                </h4>
                            </center>
                        </div>
                        <RecordView user={activeUser} startDate={daysInMonth[0]} endDate={daysInMonth[daysInMonth.length-1]}/>
                        </>
                        :<LoadingSpinner size="75px"/>}
                    </div>
                </div>
                </>
                :
                <h1>Work in Progress</h1>
                }
            </div>
        </div>
    )
}

export default Admin;