import React, { useState, useEffect, useContext } from 'react';

import DaySection from './DaySection';
import WeekSelector from './WeekSelector';

import helpers from '../helpers';
import UserContext from '../UserContext';
import api from '../api';

function Logger(props) {
    const userContext = useContext(UserContext);
    const [ entries0, setEntries0 ] = useState([]);
    const [ entries1, setEntries1 ] = useState([]);
    const [ entries2, setEntries2 ] = useState([]);
    const [ entries3, setEntries3 ] = useState([]);
    const [ entries4, setEntries4 ] = useState([]);
    const [ entries5, setEntries5 ] = useState([]);
    const [ entries6, setEntries6 ] = useState([]);
    const [ baseDayStr, setBaseDayStr ] = useState(helpers.dateToISOFragment(new Date()));
    const [ dayStrs, setDayStrs ] = useState([]);
    const [ totalHours, setTotalHours ] = useState(0);
    const [ holidays, setHolidays ] = useState(null);

    useEffect(() => {
        setDayStrs(helpers.getWeek(baseDayStr));
    }, [baseDayStr]);

    useEffect(() => {
        if(holidays === null) {
            api.getHolidays()
            .then(holidays => {
                setHolidays(holidays);
            })
            .catch(err => {
                console.error(err);
                alert("ERROR: Failed to read Holidays");
            });
        }
    }, [holidays]);

    useEffect(() => {
        const entries = [].concat(entries0, entries1, entries2, entries3, entries4, entries5, entries6);
        let total = 0;
        for(const entry of entries) {
            total += entry.hours;
        }
        setTotalHours(total);
    }, [entries0, entries1, entries2, entries3, entries4, entries5, entries6]);

    const setEntriesForDay = (dow, entries) => {
        switch(dow) {
            case 0:
                setEntries0(entries);
                break;
            case 1:
                setEntries1(entries);
                break;
            case 2:
                setEntries2(entries);
                break;
            case 3:
                setEntries3(entries);
                break;
            case 4:
                setEntries4(entries);
                break;
            case 5:
                setEntries5(entries);
                break;
            case 6:
                setEntries6(entries);
                break;
        }
    }

    const getEntriesForDay = (dow) => {
        switch(dow) {
            case 0:
                return entries0;
            case 1:
                return entries1;
            case 2:
                return entries2;
            case 3:
                return entries3;
            case 4:
                return entries4;
            case 5:
                return entries5;
            case 6:
                return entries6;                       
        }
        return [];
    }

    const dayIsReadOnly = (dayIndex) => {
        const entries = getEntriesForDay(dayIndex);
        if(entries.length === 0) return false;
        for(const entry of entries) {
            if(!entry.locked) return false;
        }
        return true;
    }

    return (
        <div className="container">
            <center>
                <h1>Timecards</h1>
                <p className="fs-5 text-muted"><i>Welcome, {userContext.loggedInUser.first} {userContext.loggedInUser.last}</i></p>
                <h2>
                    <WeekSelector baseDayStr={baseDayStr} setBaseDayStr={setBaseDayStr}/>
                </h2>
            </center>
            <div className="row mb-3">
                <div className="col text-start">
                    <p style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}><strong>Total Hours: {totalHours.toFixed(2)}</strong></p>
                </div>
            </div>
            {dayStrs.map((dayStr, i) => <div className="row mb-5">
                <DaySection
                    day={dayStr}
                    readonly={dayIsReadOnly(i)}
                    showTotal
                    entries={getEntriesForDay(i)}
                    setEntries={(entries) => {
                        setEntriesForDay(i, entries);
                    }}
                    holidays={holidays}
                />
            </div>)}
        </div>
    )
}

export default Logger;